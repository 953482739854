export default {
  data() {
    return {
      datasource: [],

      tiposInvestimento: [],

      // relacao de nomes de colunas por chaves validas
      controleColunas: {
        label: {},
        hash: {},
      },

      colunasPadrao: [
        {
          field: 'descricao_linha',
          title: `${this.$t('label.descricao')}`,
        },
        {
          field: 'tipo_investimento',
          title: `${this.$t('label.tipo_de_investimento')}`,
          datasource: (autocomplete) => new Promise((resolve) => {
            const data = this.tiposInvestimento
              .filter((tipo) => tipo.nome.indexOf(autocomplete || '') >= 0);
            resolve({ data });
          }),
          atributo: 'nome',
        },
        {
          field: 'divisao',
          title: this.$tc('label.divisao', 1),
          datasource: (autocomplete, indice) => {
            const nomesExtensao = { ...this.extensoesLinha[indice] };
            const params = {
              autocomplete,
              ...this.getFiltrosResumo(),
              ...nomesExtensao,
              nomesDivisao: this.divisoesParametrizacao()
                .map((d) => d.nomeDivisao),
            };
            return this.divisaoResource.selecaoPlanilha(params);
          },
          atributo: 'nome',
        },
        {
          field: 'cliente',
          title: this.$tc('label.cliente', 1),
          datasource: (autocomplete, indice) => {
            const nomesExtensao = { ...this.extensoesLinha[indice] };

            const params = {
              autocomplete,
              ...this.getFiltrosResumo(),
              ...nomesExtensao,
            };
            return this.clienteResource.selecaoPlanilha(params);
          },
          atributo: 'idExterno',
          atributoExibicao: 'codNome',
        },
        {
          field: 'produto',
          title: this.$tc('label.produto', 1),
          datasource: (autocomplete, indice) => {
            const nomesExtensao = { ...this.extensoesLinha[indice] };

            const params = {
              autocomplete,
              ...this.getFiltrosResumo(),
              ...nomesExtensao,
            };
            return this.produtoResource.selecaoPlanilha(params);
          },
          atributo: 'codigoNome',
        },
        {
          field: 'centro_custo',
          title: this.$tc('label.centro_custo', 1),
          datasource: (autocomplete, indice) => {
            const nomesExtensao = { ...this.extensoesLinha[indice] };
            const params = {
              autocomplete,
              ...this.getFiltrosResumo(),
              ...nomesExtensao,
            };
            return this.centroCustoResource.selecaoPlanilha(params);
          },
          atributo: 'nome',
        },
      ],
      colunasPeriodos: [],
      colunasCampos: [],
      colunas: [],
    };
  },
  methods: {
    elaboraFieldSeguro(label) {
      let hash = this.controleColunas.label[label];
      if (hash != null) {
        return hash;
      }

      const charset = 'abcdefghijklmnopqrstuvwxyz',
        len = 10;
      hash = '';
      for (let i = 0; i < len; i += 1) {
        hash
          += charset.charAt(Math.floor(Math.random() * charset.length));
      }

      this.controleColunas.hash[hash] = label;
      this.controleColunas.label[label] = hash;

      return hash;
    },
    ehColunaPadrao(label) {
      return this.nomesColunasPadrao.indexOf(label) >= 0;
    },
    ehColunaExtensao(label) {
      return this.nomesColunasExtensao.indexOf(label) >= 0;
    },
    ehColunaDinamica(label) {
      return this.nomesColunasDinamicas.indexOf(label) >= 0;
    },
    ehColunaPeriodo(label) {
      return this.controleColunas.label[label] != null;
    },
    aplicaTituloObrigatorio(identificador, titulo) {
      if (this.nomesColunasObrigatorias.indexOf(identificador) >= 0) {
        return `${titulo} *`;
      }
      return titulo;
    },
    montaColunaDinamica(definicaoMetadado) {
      const {
        labelCampo,
        nomCampo,
        tipoCampo,
        desAtributos,
      } = definicaoMetadado;
      const coluna = {
        field: this.elaboraFieldSeguro(labelCampo),
        title: this.aplicaTituloObrigatorio(labelCampo, nomCampo),
        type: tipoCampo,
        attributes: desAtributos,
      };
      if (tipoCampo === 'LISTA') {
        coluna.atributo = 'valor';
        coluna.datasource = this.montaDataSourceCampoDinamicoLista(desAtributos);
      }
      return coluna;
    },
    montaDataSourceCampoDinamicoLista(desAtributos) {
      return (autocomplete) => new Promise((resolve) => {
        const data = desAtributos.lista
          .filter((v) => v.indexOf(autocomplete || '') >= 0)
          .map((valor) => ({ valor }));
        resolve({ data });
      });
    },
    montaColunaExtensao(definicaoMetadado) {
      const titulo = definicaoMetadado.entidadeEstrangeira,
        { label, idNivelExtensao, indiceRecursao } = definicaoMetadado;

      return {
        field: label,
        title: this.aplicaTituloObrigatorio(label, titulo),
        datasource: (filtro, indice) => this
          .datasourceExtensao(label, indice, idNivelExtensao, indiceRecursao, filtro),
        atributo: 'codNomExtensao',
      };
    },
    datasourceExtensao(label, indicePlanilha, idNivelExtensao, indiceRecursao, filtro) {
      const indice = indicePlanilha;
      const nomesExtensao = { ...this.extensoesLinha[indice] };
      delete nomesExtensao[label];

      const params = {
        autocomplete: filtro,
        idNivelExtensao,
        indiceRecursao,
        ...nomesExtensao,
        ...this.getFiltrosResumo(),
      };
      return this.extensaoResource.selecaoPlanilha(params);
    },
    montaColunaPeriodo(periodo, rateioDivisao) {
      if (!rateioDivisao) {
        return {
          field: this.elaboraFieldSeguro(periodo),
          title: `${periodo} *`,
          type: 'DINHEIRO',
        };
      }
      const campos = [];
      campos.push({
        field: `coluna_calculo_monetario_${periodo.replace('Ç', 'C').replace('ç', 'c').replace(/[^0-9a-zA-Zs]/g, '')}`,
        title: `${periodo} (%)`,
        type: 'PERCENTUAL',
        cvm: true,
      });
      campos.push({
        field: this.elaboraFieldSeguro(`${periodo} (R$)`),
        title: `${periodo} (R$)`,
        type: 'DINHEIRO',
        disabled: true,
        cvm: false,
      });
      return campos;
    },
    montaColunaTotal(rateioDivisao) {
      if (!rateioDivisao) {
        return {
          field: 'total',
          title: this.$tc('label.total', 1),
          disabled: true,
          type: 'DINHEIRO',
          cvm: false,
        };
      }
      const campos = [];
      campos.push({
        field: 'total',
        title: `${this.$tc('label.total', 1)} (%)`,
        disabled: true,
        type: 'PERCENTUAL',
        cvm: true,
      });
      campos.push({
        field: 'total',
        title: `${this.$tc('label.total', 1)} (R$)`,
        disabled: true,
        type: 'DINHEIRO',
        cvm: false,
      });
      return campos;
    },
    montaColunaPadrao(label) {
      const original = this.colunasPadrao
        .filter((coluna) => coluna.field === label)[0];

      const coluna = { ...original };
      const identificador = coluna.field,
        titulo = coluna.title;

      coluna.title = this.aplicaTituloObrigatorio(identificador, titulo);

      /**
       * IF criado para remover comportamento CTRL+ENTER dos campos lista
       * 'enable: false' não é suficiente
       */
      if (this.somenteLeitura && coluna.datasource) {
        delete coluna.datasource;
      }
      return coluna;
    },
    criaColunasPeriodosEdicao() {
      const linha = this.linhasInvestimento[0];
      const { periodos } = linha;
      this.colunasPeriodos = periodos
        .map((periodo) => this.montaColunaPeriodo(periodo, this.configuracao.rateioDivisao));
    },
    montaColunas() {
      /** Array ja ordenado conforme parametrizacao */
      const { colunasLinhaInvestimento } = this.configuracao;

      colunasLinhaInvestimento.forEach((nomeCol) => {
        let coluna = null;
        if (this.ehColunaPadrao(nomeCol)) {
          coluna = this.montaColunaPadrao(nomeCol);
        } else if (this.ehColunaExtensao(nomeCol)) {
          const { mapaEntidades } = this.metadadosParametrizado;
          const definicaoMetadado = mapaEntidades[nomeCol];
          coluna = this.montaColunaExtensao(definicaoMetadado);
        } else {
          const { mapaCamposDinamicos } = this.metadadosParametrizado;
          const definicaoMetadado = mapaCamposDinamicos[nomeCol];
          coluna = this.montaColunaDinamica(definicaoMetadado);
        }
        this.colunasCampos.push(coluna);
      });

      if (this.edicao) {
        this.criaColunasPeriodosEdicao();
        if (!this.configuracao.rateioDivisao) {
          this.colunas = [
            ...this.colunasCampos,
            ...this.colunasPeriodos,
            this.montaColunaTotal(),
          ];
        } else {
          this.colunasPeriodosComDivisao = [
            ...this.colunasPeriodos,
          ];
          const arrayPeriodos = [];
          this.colunasPeriodosComDivisao.forEach((p) => { p.forEach((p1) => { arrayPeriodos.push(p1); }); });
          this.colunasPeriodosComDivisao = arrayPeriodos;
          this.colunasPeriodos = this.colunasPeriodosComDivisao;
          this.colunasTotais = [
            ...this.montaColunaTotal(this.configuracao.rateioDivisao),
          ];
          this.colunas = [
            ...this.colunasCampos,
            ...this.colunasPeriodosComDivisao,
            ...this.colunasTotais,
          ];
        }
      } else {
        this.colunas = [...this.colunasCampos];
      }
    },
    async carregaTiposInvestimento() {
      const { idsTipoInvestimento } = this.configuracao;
      await Promise.all(
        idsTipoInvestimento
          .map((idTipoInvestimento) => this.tipoInvestimentoResource
            .buscaTipoInvestimento({ idTipoInvestimento })
            .then((res) => this.tiposInvestimento.push(res.data))),
      );
    },
    parseCampoBoolean(linha, hash) {
      const valor = linha[hash];
      linha[hash] = valor === true
        ? 'SIM' : 'NAO';
    },
    parseLinha(linha) {
      const { mapaCamposDinamicos } = this.metadadosParametrizado;
      const labels = Object.keys(linha);
      labels.forEach((label) => {
        const hash = this.controleColunas.label[label];

        if (this.ehColunaDinamica(label)) {
          linha[hash] = linha[label];
          delete linha[label];

          const campo = mapaCamposDinamicos[label];
          if (campo.tipoCampo === 'BOOLEAN') {
            this.parseCampoBoolean(linha, hash);
          }
        } else if (this.ehColunaPeriodo(label)) {
          linha[hash] = linha[label];
          delete linha[label];
        }
      });
      return linha;
    },
    preencheDadosEdicao(cbReady) {
      this.carregaDadosBase()
        .then(() => {
          this.linhasInvestimento
            .forEach((linha) => {
              this.datasource.push(this.parseLinha(linha));
            });
          setTimeout(() => cbReady());
        });
    },
    preencheDadosInsercao(cbReady) {
      this.carregaDadosBase().then(() => cbReady());
    },
    async carregaDadosBase() {
      return this.carregaTiposInvestimento();
    },
    divisoesParametrizacao() {
      if (!this.configuracao.habilitaColunaDivisao) {
        return [];
      }
      return this.configuracao.divisoes;
    },
    preencheDadosIniciais(cbReady) {
      if (this.edicao) {
        this.preencheDadosEdicao(cbReady);
      } else {
        this.preencheDadosInsercao(cbReady);
      }
    },
    atualizaPeriodos(periodosOrcamentarios, rateioDivisao) {
      this.colunasPeriodos = periodosOrcamentarios
        .map((periodo) => this.montaColunaPeriodo(periodo.descricao, rateioDivisao));
      if (!rateioDivisao) {
        this.colunas = [
          ...this.colunasCampos,
          ...this.colunasPeriodos,
          this.montaColunaTotal(rateioDivisao),
        ];
      } else {
        this.colunasPeriodosComDivisao = [
          ...this.colunasPeriodos,
        ];
        const arrayPeriodos = [];
        this.colunasPeriodosComDivisao.forEach((p) => { p.forEach((p1) => { arrayPeriodos.push(p1); }); });
        this.colunasPeriodosComDivisao = arrayPeriodos;
        this.colunasPeriodos = this.colunasPeriodosComDivisao;
        this.colunasTotais = [
          ...this.montaColunaTotal(rateioDivisao),
        ];
        this.colunas = [
          ...this.colunasCampos,
          ...this.colunasPeriodosComDivisao,
          ...this.colunasTotais,
        ];
      }
      this.aplicaEstiloColunas();
      this.atualizaValorRemanescente();
      setTimeout(() => this.$refs.spreadsheet.recriar());
    },
  },
};
