<template>
  <div>
    <v-card v-if="configuracaoCarregada">
      <v-card-title>
        <div class="title">
          {{ titulo }}
        </div>
      </v-card-title>

      <v-form ref="form"
        v-model="formValido"
        lazy-validation>
        <orcamento-form-resumo
          id="aplicarPeriodo"
          ref="resumo"
          :edicao="!novo"
          :tipo-verba="tipoVerba"
          :orcamento-edicao="orcamentoEdicao"
          :configuracao="configuracao"
          :somente-leitura="!exibeSalvar"
          @OrcamentoFormResumo__AplicaPeriodo="aplicaPeriodo"
          @OrcamentoFormResumo__PeriodoPendente="setPeriodoPendente"
          @OrcamentoFormResumo__PlanejadoAlterado="planejadoAlterado"
          @OrcamentoFormResumo__UUIDImportacao="salvarImportacao"
          >
        </orcamento-form-resumo>

        <orcamento-form-linhas
          ref="linhas"
          :edicao="!novo"
          :orcamento-edicao="orcamentoEdicao"
          :configuracao="configuracao"
          :valor-planejado="valorPlanejado"
          :linhas-investimento="linhasInvestimento"
          :get-filtros-resumo="getFiltrosResumo"
          :somente-leitura="!exibeSalvar"
          v-if="exibeSpreadsheet"
          @OrcamentoFormLinhas__AtualizaValorRemanescente="atualizaValorRemanescente"
          @OrcamentoFormLinhas__HabilitaSalvar="habilitaSalvar">
        </orcamento-form-linhas>
      </v-form>

      <v-card-actions>
        <v-btn tile color="amber"
          v-if="exibeCarregarLinhas"
          :disabled="orcamentoEdicao.qtdeLinhas > LIMITE_LINHAS_SUPORTADAS"
          @click="carregarLinhas">
          <v-icon left>
            system_update_alt
          </v-icon>
          {{ $t('label.carregar_linhas', { qtdeLinhas: orcamentoEdicao.qtdeLinhas }) }}
        </v-btn>
        <v-btn
          v-if="orcamentoEdicao.id && orcamentoEdicao.idImportacaoOrcamento
                && permiteSalvar && orcamentoEdicao.qtdeLinhas > LIMITE_LINHAS_SUPORTADAS"
            class="mr-3"
            color="accent"
            @click="() => novo = true">
            Habilitar Importação ({{orcamentoEdicao.qtdeLinhas}} linhas)
        </v-btn>
        <v-btn
          v-if="orcamentoEdicao.id && orcamentoEdicao.idImportacaoOrcamento && orcamentoEdicao.qtdeLinhas > LIMITE_LINHAS_SUPORTADAS"
            class="mr-3"
            color="accent"
            @click="exportarArquivo">
            Exportar arquivo
            <v-icon>
              attach_file
            </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn id="voltar" @click="abrirModalConfirmacao('voltar')">
          {{ $t('label.voltar') }}
        </v-btn>
        <v-btn id="salvar" @click="abrirModalConfirmacao('salvar')"
          :dark="permiteSalvar"
          :disabled="!permiteSalvar"
          v-if="exibeSalvar"
          color="primary">
          {{ $t('label.salvar') }}
        </v-btn>
        <v-btn id="concluir" @click="abrirModalConfirmacao('concluir')"
          color="red"
          :dark="permiteConcluir"
          :disabled="!permiteConcluir"
          v-if="!possuiFluxoHabilitado">
          {{ $t('label.concluir') }}
        </v-btn>
        <v-btn @click="solicitarAprovacaoOrcamento"
          color="red"
          dark
          v-if="possuiFluxoHabilitado && permiteSolicitarAprovacao">
          {{ $t('label.solicitar_aprovacao') }}
        </v-btn>
        <v-btn @click="abrirJustificativaAprovar()"
          dark
          color="primary"
          v-show="exibeBtnAvaliacao">
          {{ $t('label.aprovar') }}
        </v-btn>
        <v-btn @click="abrirJustificativaReprovar()"
          dark
          color="red"
          v-show="exibeBtnAvaliacao">
          {{ $t('label.reprovar') }}
        </v-btn>
        <v-btn @click="abrirJustificativaAnalise()"
          dark
          color="primary"
          v-show="exibeBtnAvaliacao">
          {{ $tc('label.enviar_analise', 1) }}
        </v-btn>
      </v-card-actions>

      <orcamento-form-validacao
        ref="validacao"
        v-if="carregouMetadados()"
        :configuracao="configuracao"
        :get-celula-entidade="getCelulaEntidade"
        :metadados-orcamento="metadadosOrcamento"
        :metadados-linha="metadadosLinha"/>
    </v-card>

    <confirm
      ref="modalConfirmacao"
      :message="mensagemConfirmacao"
      @agree="executarAcao"
    />
    <confirm
      ref="modalOrcamentoMassivo"
      :message="mensagemConfirmacao"
      @agree="solicitarAprovacao"
    />
    <orcamento-justificativa
      ref="modalJustificativa"
      :obrigatorio="justificativaObrigatoria"
      :salvarJustificativa="actionJustificativa"
      :observacao="justificativa"
      :somenteLeitura="justificativaSomenteLeitura"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import { toColumnName } from '../../common/functions/helpers';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';

import OrcamentoFormResumo from './OrcamentoFormResumo';
import OrcamentoFormLinhas from './OrcamentoFormLinhas';
import OrcamentoConfiguracao from './OrcamentoConfiguracao';
import OrcamentoFormValidacao from './OrcamentoFormValidacao';
import OrcamentoFormFluxo from './OrcamentoFormFluxo';
import OrcamentoJustificativa from './OrcamentoJustificativa';

import getBasePath from '../../common/functions/api-resource';
import downloads from '../../common/resources/downloads';

export default {
  name: 'OrcamentoForm',
  components: {
    OrcamentoFormResumo,
    OrcamentoFormLinhas,
    OrcamentoFormValidacao,
    OrcamentoJustificativa,
    Confirm,
  },
  mixins: [
    OrcamentoFormFluxo,
  ],
  props: {
    edicao: Boolean,
    novo: Boolean,
  },
  computed: {
    ...mapGetters('metadados', [
      'getOrcamentoMetadado',
      'getLinhaInvestimentoMetadado',
    ]),
    ...generateComputed('ORC', [
      'canAccessPage',
    ]),
    titulo() {
      return !this.novo
        ? `${this.orcamentoEdicao.descricao || ''} - ${this.nomeTipoVerba}`
        : this.nomeTipoVerba;
    },
    nomeTipoVerba() {
      const tipoVerba = !this.novo
        ? this.orcamentoEdicao.tipoVerba : this.tipoVerba;

      return tipoVerba ? tipoVerba.nome : '';
    },
    configuracaoCarregada() {
      return this.configuracao != null;
    },
    permiteSalvar() {
      return this.formValido
        && !this.pendenteRecalculoPeriodo;
    },
    permiteConcluir() {
      return this.permiteSalvar;
    },
    permiteSolicitarAprovacao() {
      return this.edicao
        && this.orcamentoEdicao.status === 'EM_CADASTRO'
        && this.orcamentoEdicao.solicitanteFluxo;
    },
    linhasInvestimento() {
      return !this.novo
        ? this.orcamentoEdicao.linhasInvestimento
        : [];
    },
    exibeSpreadsheet() {
      if (!this.configuracaoCarregada) {
        return false;
      }
      const { linhasInvestimento } = this.orcamentoEdicao;
      return this.novo || (!this.novo && linhasInvestimento.length > 0);
    },
    exibeSalvar() {
      if (this.novo) {
        return true;
      }
      const { linhasInvestimento, status } = this.orcamentoEdicao;
      return status === 'EM_CADASTRO' && linhasInvestimento.length > 0;
    },
    exibeCarregarLinhas() {
      if (this.novo) {
        return false;
      }
      const { linhasInvestimento } = this.orcamentoEdicao;
      return linhasInvestimento.length === 0;
    },
    possuiFluxoHabilitado() {
      return this.configuracao.fluxoHabilitado;
    },
  },
  data() {
    return {
      tipoVerbaResource: this.$api.orcamentoTipoVerba(this.$resource),
      configuracaoResource: this.$api.orcamentoConfiguracao(this.$resource),
      orcamentoResource: this.$api.orcamento(this.$resource),
      orcamentoListagemResource: this.$api.orcamentoListagem(this.$resource),

      tipoVerba: null,
      formValido: false,
      configuracao: null,
      valorPlanejado: 0,

      orcamentoEdicao: {},
      idOrcamento: null,

      pendenteRecalculoPeriodo: false,

      metadadosLinha: null,
      metadadosOrcamento: null,

      mensagemConfirmacao: null,
      acaoConfirmacao: null,

      LIMITE_LINHAS_SUPORTADAS: 5000,
      habilitaEdicaoImportacao: false,
      habilitarSalvar: false,
    };
  },
  methods: {
    solicitarAprovacaoOrcamento() {
      const { indOrcamentoMassivo } = this.orcamentoEdicao;
      if (!indOrcamentoMassivo) {
        this.abrirModalConfirmacao('solicitarAprovacao');
        return;
      }

      this.mensagemConfirmacao = this.$t('message.orcamento_massivo_confirmar_solicitacao');
      setTimeout(() => this.$refs.modalOrcamentoMassivo.open());
    },
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    getFiltrosResumo() {
      return this.$refs.resumo.getValoresAutocomplete();
    },
    getCelulaEntidade(entidade) {
      const indice = this.$refs.linhas.getIndiceColPorLabel(entidade);
      return toColumnName(indice).toUpperCase();
    },
    planejadoAlterado(planejado) { // eslint-disable-line
      this.valorPlanejado = planejado;
    },
    aplicaPeriodo(periodosOrcamentarios, rateioDivisao) {
      this.$refs.linhas.atualizaPeriodos(periodosOrcamentarios, rateioDivisao);
      this.$toast(this.$t('message.periodo_adicionado_planilha'));
      this.pendenteRecalculoPeriodo = false;
    },
    setPeriodoPendente() {
      this.pendenteRecalculoPeriodo = true;
    },
    habilitaSalvar(entidade) {
      this.habilitarSalvar = entidade;
    },
    atualizaValorRemanescente(totalDistribuido) {
      this.$refs.resumo.atualizaValorRemanescente(totalDistribuido);
    },
    carregarLinhas() {
      const { id: idOrcamento } = this.orcamentoEdicao;

      this.orcamentoListagemResource.buscarLinhasOrcamento({ idOrcamento })
        .then((res) => {
          const { data: linhasInvestimento } = res;
          this.orcamentoEdicao = {
            ...this.orcamentoEdicao,
            linhasInvestimento,
          };
        });
    },
    carregarOrcamento(idOrcamento) {
      this.idOrcamento = idOrcamento;

      this.orcamentoListagemResource.buscarOrcamento({ idOrcamento })
        .then((res) => {
          this.orcamentoEdicao = res.data;

          const { planejado, tipoVerba } = this.orcamentoEdicao;
          const idTipoVerba = tipoVerba.id;
          this.planejadoAlterado(planejado);
          const promises = [
            this.verificarUsuarioSolicitante(),
            this.verificarUsuarioAprovador(),
          ];

          if (promises.length > 0) {
            Promise.all(promises)
              .then(() => this.$forceUpdate())
              .then(() => this.carregarTipoVerba(idTipoVerba));
          } else {
            setTimeout(() => this.$forceUpdate());
          }
        })
        .catch((err) => {
          if (err.status === 403) {
            this.$toast(this.$t('message.acesso_negado_orcamento'));
            this.$router.push({ name: 'orcamento' });
          } else {
            this.$toast(err.data.error);
          }
        });
    },
    carregarTipoVerba(id) {
      this.tipoVerbaResource.buscarTipoVerba({ id })
        .then((res) => {
          this.tipoVerba = res.data;
          return this.tipoVerba.id;
        })
        .then((idTipoVerba) => this.carregarConfiguracao(idTipoVerba))
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarConfiguracao(idTipoVerba) {
      const { idOrcamento } = this;

      const promise = this.novo
        ? this.configuracaoResource.buscarConfigVigente({ idTipoVerba })
        : this.configuracaoResource.buscarConfigSnapshot({ idOrcamento });

      promise.then((res) => {
        this.configuracao = new OrcamentoConfiguracao(res.data);
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    abrirModalConfirmacao(acao) {
      if (acao === 'voltar') {
        this.mensagemConfirmacao = this.$t('message.deseja_voltar');
        this.acaoConfirmacao = 'voltar';
      } else if (acao === 'concluir') {
        this.mensagemConfirmacao = this.$t('message.deseja_concluir', { text: this.$tc('label.orcamento', 1).toLowerCase() });
        this.acaoConfirmacao = 'concluir';
      } else if (acao === 'salvar') {
        this.mensagemConfirmacao = this.$t('message.deseja_salvar', { text: this.$tc('label.orcamento', 1).toLowerCase() });
        this.acaoConfirmacao = 'salvar';
      } else if (acao === 'solicitarAprovacao') {
        this.mensagemConfirmacao = this.$t('message.deseja_solicitar_aprovacao_orcamento');
        this.acaoConfirmacao = 'solicitarAprovacao';
      }
      setTimeout(() => this.$refs.modalConfirmacao.open());
    },
    executarAcao() {
      if (this.acaoConfirmacao === 'voltar') {
        this.voltar();
      } else if (this.acaoConfirmacao === 'concluir') {
        this.concluir();
      } else if (this.acaoConfirmacao === 'salvar') {
        this.salvar();
      } else if (this.acaoConfirmacao === 'solicitarAprovacao') {
        this.salvarESolicitarAprovacao();
      }
    },
    voltar() {
      this.$router.push({ name: 'orcamento' });
    },
    concluir() {
      if (this.configuracao.rateioDivisao && !this.habilitarSalvar) {
        this.$toast(this.$t('message.recalcular_orcamento_rateio'));
        return;
      }
      const resumo = this.$refs.resumo.getObjetoResumo(),
        linhas = this.$refs.linhas.getObjetoLinha();
      const orcamento = { ...resumo, ...linhas };

      if (this.realizaValidacaoSimples(orcamento)) return;
      if (!this.$refs.form.validate()) return;

      const promise = this.novo
        ? this.orcamentoResource.salvar(orcamento)
        : this.orcamentoResource.atualizar(orcamento);

      promise
        .then((res) => {
          const idOrcamento = res.data;
          return this.orcamentoResource.concluir({ idOrcamento }, { idOrcamento });
        })
        .then(() => {
          this.$router.push({ name: 'orcamento' });
          this.$toast(this.$t('message.orcamento_concluido'));
        })
        .catch((err) => this.$refs.validacao.apresentaErrosServidor(err));
    },
    salvarESolicitarAprovacao() {
      if (this.configuracao.rateioDivisao && !this.habilitarSalvar) {
        this.$toast(this.$t('message.recalcular_orcamento_rateio'));
        return;
      }
      const resumo = this.$refs.resumo.getObjetoResumo(),
        linhas = this.$refs.linhas.getObjetoLinha();
      const orcamento = { ...resumo, ...linhas };

      if (this.realizaValidacaoSimples(orcamento)) return;
      if (!this.$refs.form.validate()) return;

      const promise = this.novo
        ? this.orcamentoResource.salvar(orcamento)
        : this.orcamentoResource.atualizar(orcamento);

      promise
        .then(() => {
          this.solicitarAprovacao();
        })
        .catch((err) => this.$refs.validacao.apresentaErrosServidor(err));
    },
    salvar() {
      if (this.configuracao.rateioDivisao && !this.habilitarSalvar) {
        this.$toast(this.$t('message.recalcular_orcamento_rateio'));
        return;
      }
      const resumo = this.$refs.resumo.getObjetoResumo(),
        linhas = this.$refs.linhas.getObjetoLinha();
      const orcamento = { ...resumo, ...linhas };

      if (this.realizaValidacaoSimples(orcamento)) return;
      if (!this.$refs.form.validate()) return;

      if (this.novo) {
        this.salvarNovo(orcamento);
      } else {
        this.atualizar(orcamento);
      }
    },
    salvarNovo(orcamento) {
      return this.orcamentoResource.salvar(orcamento)
        .then((res) => {
          const idOrcamento = res.data;
          this.alternaRota(idOrcamento);
          this.$toast(this.$t('message.adicionado_confira_tabela'));
        })
        .catch((err) => this.$refs.validacao.apresentaErrosServidor(err));
    },
    atualizar(orcamento) {
      return this.orcamentoResource.atualizar(orcamento)
        .then((res) => {
          const idOrcamento = res.data;
          this.alternaRota(idOrcamento);
          this.$toast(this.$t('message.atualizado_com_sucesso'));
        })
        .catch((err) => this.$refs.validacao.apresentaErrosServidor(err));
    },
    salvarImportacao(uuid) {
      if (this.permiteSalvar) {
        const resumo = this.$refs.resumo.getObjetoResumo();
        const orcamento = { ...resumo };

        if (!this.$refs.form.validate()) return;

        orcamento.idImportacao = uuid;
        this.orcamentoResource.salvarImportacao(orcamento)
          .then((res) => {
            const idOrcamento = res.data;
            this.alternaRota(idOrcamento);
            this.$toast(this.$t('message.adicionado_confira_tabela'));
          })
          .catch((err) => {
            try {
              console.table(err);
              console.table(JSON.parse(err.bodyText));
              this.$refs.resumo.habilitarImportacao();
              this.$refs.validacao.apresentaErrosServidor(err);
            } catch (e) {
              console.table(e);
              this.buscarIdOrcamentoPorIdImportacao(uuid, 1);
            }
          });
      } else {
        this.$toast(this.$t('errors.orcamento.salvar_importacao_sem_periodo'));
      }
    },
    buscarIdOrcamentoPorIdImportacao(uuid, contagemImportacao) {
      if (contagemImportacao < 48) {
        this.$toast('Importação realizada, aguarde o processamento do arquivo e você será redirecionado para a tela deste orçamento.', 'bottom', 5E3);
        setTimeout(() => {
          this.orcamentoResource.buscarIdOrcamentoPorIdImportacao({ idImportacao: uuid })
            .then((res) => {
              const idOrcamento = res.data;
              if (idOrcamento) {
                this.alternaRota(idOrcamento);
                this.$toast(this.$t('message.adicionado_confira_tabela'));
              } else {
                this.buscarIdOrcamentoPorIdImportacao(uuid, contagemImportacao + 1);
              }
            })
            .catch((err) => {
              this.$toast(err.data.error);
            });
        }, 5E3);
      } else {
        this.$toast('Erro ao salvar dados do orcamento.', 'bottom', 5E3);
        this.$router.push({ name: 'orcamento' });
      }
    },
    exportarArquivo() {
      const basePathOrcamento = getBasePath('job', 'importacao-orcamento');
      const resource = downloads(this.$http);

      resource.downloadGet(basePathOrcamento, {
        param: `?idOrcamento=${this.orcamentoEdicao.id}&idTipoVerba=${this.orcamentoEdicao.tipoVerba.id}`,
        url: 'exportar',
      })
        .then(() => {
          this.$toast(this.$t('message.download_efetuado'));
        })
        .catch(() => {
          this.$toast('Erro ao baixar. Tente novamente.');
        });
    },
    alternaRota(idOrcamento) {
      const routeEdicao = 'editarOrcamento';

      let { name } = this.$router.currentRoute;
      name = name === routeEdicao
        ? name.concat('_') : routeEdicao;

      this.$router.replace({
        name,
        params: { idOrcamento, from: 'orcamento' },
      });
    },
    realizaValidacaoSimples(orcamento) {
      const { planejado, linhasInvestimento } = orcamento;
      if ((!planejado || planejado <= 0) && !this.configuracao.ehPercentual && !this.configuracao.rateioDivisao) {
        this.$toast(this.$t('errors.orcamento.valor_planejado_obrigatorio'));
        return true;
      }
      if (!linhasInvestimento || linhasInvestimento.length === 0) {
        this.$toast(this.$t('errors.planilha.linha_investimento_requerida'));
        return true;
      }
      return false;
    },
    carregarSeUsuarioPossuirAcesso(idOrcamento) {
      this.orcamentoListagemResource.verificarSeUsuarioPossuiAcesso({ idOrcamento })
        .then((res) => {
          if (res.data) {
            this.carregarOrcamento(idOrcamento);
          } else {
            this.$router.push({ name: 'inicio' });
            this.$toast('Acesso negado');
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    carregouMetadados() {
      return this.metadadosOrcamento != null && this.metadadosLinha != null;
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
      return;
    }

    this.metadadosOrcamento = this.getOrcamentoMetadado;
    this.metadadosLinha = this.getLinhaInvestimentoMetadado;

    if (this.novo) {
      const { idTipoVerba } = this.$route.params;
      this.carregarTipoVerba(idTipoVerba);
    } else {
      const { idOrcamento, from } = this.$route.params;
      if (!from) {
        this.carregarSeUsuarioPossuirAcesso(idOrcamento);
      } else {
        this.carregarOrcamento(idOrcamento);
      }
      this.habilitaSalvar(true);
    }
  },
};
</script>
