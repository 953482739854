<template>
  <v-container fluid grid-list-md>
    <v-row v-if="!somenteLeitura">
      <v-col cols="12" sm="1">
        <input-number
            id="qtdLinhas_input"
            prepend-icon=""
            label=""
            v-model="valorQtdLinhasAdicionar"/>
      </v-col>
      <v-col cols="12" sm="3">
        <v-btn id="add_linhas" @click="adicionarLinhas()" style="margin-top: 0.8em;">
          {{ $tc('label.adicionar_linha', 2) }}
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon
                   style="margin-top: 0.8em;"
                   id="rateio-botao-exportar"
                   v-on="on"
                   :v-if="isOrcamentoEmCadastro"
                   @click="exportarDados">
              <v-icon>get_app</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.exportar_conteudo') }}</span>
        </v-tooltip>
      </v-col>
      <v-col cols="12" sm="1" v-if="configuracao.rateioDivisao">
        <v-btn id="add_linhas" @click="calcularValorMonetario()" style="margin-top: 0.8em;">
          {{ $tc('label.calcular_valor_monetario') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <spreadsheet
          ref="spreadsheet"
          v-if="planilhaPronta"
          :toolbar="false"
          :sheetsbar="false"
          :rows="rowsNumber"
          :data="datasource"
          :colunas="colunas"
          :estilos="estilos"
          :formulas="formulas"
          :selectRange="celulaAtiva"
          :tamanhoColunas="tamanhoColunas"
          :abasListaValores="abasListaValores"
          :frozenRows="1"
          :autocomplete="true"
          :ignored-columns-on-paste="['total']"
          @DATASOURCE_CHANGE="datasourceAlterado"
          @SPREADSHEET_AUTOCOMPLETE="onAutocomplete"/>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import Spreadsheet from '../../shared-components/spreadsheet/Spreadsheet';

import { camelCase } from '../../common/functions/helpers';
import { getNivelExtensao } from '../../shared-components/metadados/metadadosUtils';

import InputNumber from '../../shared-components/inputs/InputNumber';

import OrcamentoFormLinhasEstilizacao from './OrcamentoFormLinhasEstilizacao';
import OrcamentoFormLinhasPreenchimento from './OrcamentoFormLinhasPreenchimento';
import OrcamentoFormLinhasAlteracao from './OrcamentoFormLinhasAlteracao';

export default {
  components: {
    Spreadsheet,
    InputNumber,
  },
  mixins: [
    OrcamentoFormLinhasEstilizacao,
    OrcamentoFormLinhasPreenchimento,
    OrcamentoFormLinhasAlteracao,
  ],
  props: {
    edicao: Boolean,
    orcamentoEdicao: Object,
    somenteLeitura: Boolean,
    configuracao: {
      type: Object,
      required: true,
    },
    valorPlanejado: Number,
    linhasInvestimento: {
      type: Array,
      default: () => [],
    },
    getFiltrosResumo: {
      type: Function,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('metadados', [
      'getLinhaInvestimentoMetadado',
      'getProdutoMetadado',
      'getClienteMetadado',
      'getCentroCustoMetadado',
      'getDivisaoMetadado',
    ]),
    nomesColunasPadrao() {
      return this.colunasPadrao.map((coluna) => coluna.field);
    },
    nomesColunasExtensao() {
      return this.configuracao.colunasExtensaoLinha;
    },
    nomesColunasDinamicas() {
      return this.configuracao.colunasDinamicasLinha;
    },
    nomesColunasObrigatorias() {
      return [
        ...this.configuracao.colunasObrigatoriasLinha,
        ...this.colunasFixas,
      ];
    },
    isOrcamentoEmCadastro() {
      return this.edicao && this.orcamentoEdicao.status === 'EM_CADASTRO';
    },
  },
  data() {
    return {
      anoFiscalResource: this.$api.anoFiscal(this.$resource),
      centroCustoResource: this.$api.centroCusto(this.$resource),
      clienteResource: this.$api.cliente(this.$resource),
      produtoResource: this.$api.produto(this.$resource),
      divisaoResource: this.$api.divisao(this.$resource),
      tipoInvestimentoResource: this.$api.tipoInvestimento(this.$resource),
      extensaoResource: this.$api.extensao(this.$resource),
      orcamentoListagemResource: this.$api.orcamentoListagem(this.$resource),
      estiloSomenteLeitura: { enable: false, color: 'rgb(0,0,0)' },
      metadadosParametrizado: null,
      planilhaPronta: false,

      valorQtdLinhasAdicionar: 0,
    };
  },
  methods: {
    getObjetoLinha() {
      const linhasInvestimento = [];
      const linhas = this.linhasValidas();
      linhas.forEach((linha) => {
        const { id } = linha;
        const chaveLinha = this.identificadorLinha(linha);
        const indiceLinha = this.getIndiceLinha(chaveLinha);

        const linhaInvestimento = { id, indiceLinha };

        this.formataValoresPadrao(linha, linhaInvestimento);
        this.formataExtensoes(linha, linhaInvestimento);
        this.formataCamposDinamicos(linha, linhaInvestimento);
        this.formataDesdobramento(linha, linhaInvestimento);

        linhasInvestimento.push(linhaInvestimento);
      });

      return { linhasInvestimento };
    },
    formataValoresPadrao(linha, linhaInvestimento) {
      const colunas = this.colunas.map((col) => col.field),
        colunasPadrao = this.nomesColunasPadrao;

      colunas
        .filter((coluna) => colunasPadrao.indexOf(coluna) >= 0)
        .filter((coluna) => linha[coluna] != null && linha[coluna] !== '')
        .forEach((coluna) => {
          linhaInvestimento[camelCase(coluna)] = linha[coluna];
        });
    },
    formataExtensoes(linha, linhaInvestimento) {
      const colunas = this.colunas.map((col) => col.field),
        colunasExtensao = this.nomesColunasExtensao,
        metadados = this.getLinhaInvestimentoMetadado,
        dependencias = metadados.mapaEntidades;

      const mapaExtensoes = {};
      linhaInvestimento.mapaExtensoes = mapaExtensoes;

      colunas
        .filter((coluna) => colunasExtensao.indexOf(coluna) >= 0)
        .filter((coluna) => linha[coluna] != null && linha[coluna] !== '')
        .forEach((coluna) => {
          const nivelExtensao = getNivelExtensao(metadados, coluna),
            codNomExtensao = linha[coluna];
          const chaveMapa = dependencias[coluna].campo;

          mapaExtensoes[chaveMapa] = { ...nivelExtensao, codNomExtensao };
        }, this);
    },
    formataCamposDinamicos(linha, linhaInvestimento) {
      const colunasDinamicas = this.nomesColunasDinamicas;

      const mapaCamposDinamicos = {};
      linhaInvestimento.mapaCamposDinamicos = mapaCamposDinamicos;

      this.colunas
        .filter((coluna) => {
          const hash = coluna.field,
            label = this.controleColunas.hash[hash];
          return colunasDinamicas.indexOf(label) >= 0;
        })
        .filter((coluna) => linha[coluna.field] != null && linha[coluna.field] !== '')
        .forEach((coluna) => {
          const hash = coluna.field;
          let valor = linha[hash];
          if (coluna.type === 'BOOLEAN') {
            valor = valor === 'SIM';
          }

          const label = this.controleColunas.hash[hash];
          mapaCamposDinamicos[label] = valor;
        }, this);
    },
    formataDesdobramento(linha, linhaInvestimento) {
      const colunas = this.colunas.map((col) => col.field),
        colunasPeriodo = this.colunasPeriodos.map((col) => col.field);

      colunas
        .filter((coluna) => colunasPeriodo.indexOf(coluna) >= 0)
        .forEach((hash) => {
          if (!linhaInvestimento.desdobramentos) {
            linhaInvestimento.desdobramentos = [];
          }
          const { desdobramentos } = linhaInvestimento;
          let desdobramento = {};
          if (this.configuracao.rateioDivisao) {
            if (hash.includes('coluna_calculo_monetario')) {
              this.periodoPercentual = this.colunasPeriodos.filter((a) => a.field === hash);
              desdobramento = {
                indPercentualRateio: true,
                valor: linha[hash],
                periodo: this.periodoPercentual[0].title.replace('(%)', '').trim(),
              };
            } else {
              desdobramento = {
                indPercentualRateio: false,
                valor: linha[hash],
                periodo: this.controleColunas.hash[hash].replace('(R$)', '').trim(),
              };
            }
          } else {
            desdobramento = {
              valor: linha[hash],
              periodo: this.controleColunas.hash[hash],
            };
          }

          desdobramentos.push(desdobramento);
        }, this);
    },
    datasourceAlterado(alteracao) {
      this.atualizaValorRemanescente();
      this.trataAlteracao(alteracao);
      if (alteracao.field !== undefined && alteracao.field.includes('coluna_calculo_monetario')) {
        this.$emit('OrcamentoFormLinhas__HabilitaSalvar', false);
      }
      if (alteracao.field !== undefined && alteracao.field.includes('divisao')) {
        const datasource = this.$refs.spreadsheet.getDataSource();
        datasource.forEach((dado) => {
          Object.keys(dado).forEach((item) => {
            if (item.includes('coluna_calculo_monetario_') && dado[item] !== null && dado[item] > 0) {
              this.$emit('OrcamentoFormLinhas__HabilitaSalvar', false);
            }
          });
        });
      }
    },
    atualizaValorRemanescente() {
      if (this.colunasPeriodos.length === 0) return;

      const colsPeriodo = this.colunasPeriodos.map((col) => col.field);
      const linhas = this.linhasValidas();
      const valorDistribuido = linhas
        .map((linha) => colsPeriodo
          .map((col) => linha[col])
          .filter((valor) => valor != null)
          .reduce((previo, atual) => previo + atual, 0))
        .reduce((previo, atual) => previo + atual, 0);

      this.$emit('OrcamentoFormLinhas__AtualizaValorRemanescente', valorDistribuido);
    },
    linhasValidas() {
      const datasource = this.$refs.spreadsheet.getDataSource();
      return datasource
        .filter((r) => (r.descricao_linha && r.descricao_linha !== '')
              || (r.tipo_investimento && r.tipo_investimento !== '')
              || this.validaSePreencheuColunaValor(r));
    },
    validaSePreencheuColunaValor(linha) {
      const colunas = this.colunas.map((col) => col.field),
        colunasPeriodo = this.colunasPeriodos.map((col) => col.field);
      return colunas
        .filter((coluna) => colunasPeriodo.indexOf(coluna) >= 0)
        .findIndex((hash) => linha[hash] && linha[hash] > 0) >= 0;
    },
    exportarDados() {
      this.$refs.spreadsheet.exportarExcel();
    },
    iniciaPlanilha() {
      this.montaColunas();
      this.preencheDadosIniciais(() => {
        this.aplicaEstiloColunas();
        this.planilhaPronta = true;
      });
    },
    cruzaMetadados(metadadosCampo, entidade) {
      const { mapaEntidades } = this.metadadosParametrizado;

      const dependenciasRef = Object.values(metadadosCampo.mapaEntidades)
        .map((dependencia) => dependencia.label);

      const dependenciasEntidade = Object.values(mapaEntidades);
      dependenciasEntidade
        .filter((dependencia) => dependenciasRef.indexOf(dependencia.label) >= 0)
        .forEach((dependencia) => {
          dependencia.dependentes.push(entidade.toLowerCase());
        });
    },
    configuraMetadadoCruzados() {
      const configuracoes = [
        {
          entidade: 'cliente',
          metadadosCampo: this.getClienteMetadado,
        },
        {
          entidade: 'produto',
          metadadosCampo: this.getProdutoMetadado,
        },
        {
          entidade: 'centro_custo',
          metadadosCampo: this.getCentroCustoMetadado,
        },
        {
          entidade: 'divisao',
          metadadosCampo: this.getDivisaoMetadado,
        },
      ];

      configuracoes.forEach((cfg) => {
        const { metadadosCampo, entidade } = cfg;
        this.cruzaMetadados(metadadosCampo, entidade);
      }, this);
    },
    adicionarLinhas() {
      const numeroLinhasAtual = this.rowsNumber;
      this.rowsNumber += this.valorQtdLinhasAdicionar;

      if (this.rowsNumber > 5000) {
        this.rowsNumber = numeroLinhasAtual;
        this.$toast('Número de linhas máximo permitido na planilha é 5000 linhas. Utilize a importação!', 'bottom', 8E3);
      }
      this.valorQtdLinhasAdicionar = 0;
      setTimeout(() => this.$refs.spreadsheet.recriar());
      this.aplicaEstiloColunas();
    },
    calcularValorMonetario() {
      const periodos = this.getFiltrosResumo().idsPeriodosOrcamentarios;
      const idsPeriodos = periodos;
      const tipo = 'PLANEJAMENTO_ORCAMENTARIO';
      Promise.all([
        this.anoFiscalResource.buscarDadosPeriodosAnoFiscal({
          idsPeriodos,
          tipo,
        }),
      ]).then((res) => {
        this.periodosAchados = res[0].data.resposta;
        const datasource = this.$refs.spreadsheet.getDataSource();
        if (this.validaCalcularValorMonetario(periodos, datasource)) {
          let valorMonetarioCalculado = false;
          datasource.forEach((dado) => {
            const dadosParametros = [];
            Object.keys(dado).forEach((item) => {
              if (item.includes('coluna_calculo_monetario_')) {
                if (dado[item] !== null && dado[item] > 0) {
                  if (dado.divisao === null || dado.divisao === undefined) {
                    return this.$toast(this.$t('message.selecione_divisao'));
                  }
                  const idPeriodoSelecionado = this.periodosAchados.filter((el) => el.descricao.replace('Ç', 'C').replace('ç', 'c').replace(/[^0-9a-zA-Zs]/g, '') === item.replace('coluna_calculo_monetario_', '')).map((r) => r.id)[0];
                  if (idPeriodoSelecionado === undefined) {
                    delete dado[item];
                    const mesRemovido = item.split('coluna_calculo_monetario_');
                    const hash = this.controleColunas.label[mesRemovido[1].concat(' (R$)')];
                    delete dado[hash];
                  } else {
                    dadosParametros.push({
                      idPeriodo: idPeriodoSelecionado,
                      divisao: dado.divisao,
                      vlrPercentual: dado[item],
                    });
                  }
                  return true;
                }
                const mesRemovido = item.split('coluna_calculo_monetario_');
                const hash = this.controleColunas.label[mesRemovido[1].concat(' (R$)')];
                if (dado[hash] !== null && dado[hash] !== undefined) {
                  dado[hash] = null;
                  valorMonetarioCalculado = true;
                  return true;
                }
              }
              return true;
            });
            if (dadosParametros.length !== periodos.length && !valorMonetarioCalculado) {
              valorMonetarioCalculado = false;
              return this.$toast(this.$t('errors.planilha.valor_percentual_incorreto'));
            }
            Promise.all([
              this.orcamentoListagemResource.buscarValorPeriodo(dadosParametros),
            ]).then((valores) => {
              this.valores = valores[0].data;
              this.valores.forEach((valor) => {
                const mesConvertido = valor.mes;
                if (valor.valor > 0) {
                  const mes = mesConvertido.concat(' (R$)');
                  const hash = this.controleColunas.label[mes];
                  dado[hash] = valor.valor;
                  return true;
                }
                const mes = mesConvertido.concat(' (R$)');
                const hash = this.controleColunas.label[mes];
                dado[hash] = valor.valor;
                return this.$toast(this.$t('errors.planilha.sellin_nao_encontrado_mes').concat(' ').concat(mesConvertido).concat('/')
                  .concat(valor.periodo_ano));
              });
              this.$refs.spreadsheet.recriar();
            });
            this.$emit('OrcamentoFormLinhas__HabilitaSalvar', true);
            return true;
          });
        }
      });
    },
    validaCalcularValorMonetario(periodos, dadosPlanilha) {
      if (!dadosPlanilha.length > 0) {
        return this.$toast(this.$t('errors.planilha.linha_investimento_requerida'));
      }
      return true;
    },
  },
  mounted() {
    const metadados = this.getLinhaInvestimentoMetadado;
    this.metadadosParametrizado = this.configuracao.aplicaParametrizacaoLinha(metadados);

    this.configuraMetadadoCruzados();
    this.iniciaPlanilha();
  },
};
</script>
